import BaseOperation from "./BaseOperation";
import { getLabelPoint } from "../../style/index";

export default class DrawPointOperation extends BaseOperation {
  static NAME = "DRAW_POINT"
  constructor(props) {
    let defaultParam = Object.assign(
      {
        name: DrawPointOperation.NAME,
        eventMap: [
          {
            type: "LEFT_CLICK",
            callback: (map, event, layer) => {
              this.addPoint(map, event, layer);
            },
          },
        ],
      },
      props
    );
    super(defaultParam);
    this._idHead = defaultParam.id || Cesium.createGuid();
    this._drawType = defaultParam.drawType;
  }

  set idHead(value) {
    this._idHead = value
  }

  get idHead() {
    return this._idHead
  }

  get drawType() {
    return this._drawType
  }

  addPoint(map, event) {
    let ray = map.camera.getPickRay(event.position);
    let position = map.scene.globe.pick(ray, map.scene);
    const fn = (args, Constructor)=>{
        let node = new Constructor({
            id: args.id? args.id: `${this.idHead}`,
            name: args.name,
            createBy: args.createBy,
            position: position,
            color: args.color
        })
        this._layer.addNode(node);
    }
    // 目前根据转geojson是根据entity以后会扩展node
    this._finish(new Cesium.Entity({
      id: `${this.idHead}`,
      position: position,
      ...getLabelPoint(`${this.idHead}`, this.drawType),
      drawType: this._drawType
    }), position, fn);
  }

  _finish(primitive, position, done) {
    this.idHead = Cesium.createGuid();
    if (this._afterFinish) {
      this._afterFinish(primitive, this._drawType, done);
    }
  }

  destroy(map, layer) {}
}
