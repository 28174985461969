<script>
import { mapGetters, mapMutations } from "vuex";
import userNoFlyZoneHook from '@/components/cesium/js/hooks/noFlyZoneHook';
import {getNoFlyZoneList} from '@/api/noFlyZone/index';

export default {
    data() {
        return {
            // 禁飞区相关
            requestTimer: null,
            noflySwitchData: false,
            noFlyData: [],
            temporaryData: [],
            heightLimitData: [],
            bufferZoneData: [],
            customZoneData: [],
            noflyCheckListData: [],
            noflyTypeValueData: []
        }
    },
    computed: {
        ...mapGetters([
            // 禁飞区
            "noflySwitch",
            "noflyCheckList",
            "noflyTypeValue"
        ]),
    },
    activated() {
        this.$nextTick(() => {
            this.noflySwitchData = this.noflySwitch;
            this.noflyCheckListData = this.noflyCheckList;
            this.noflyTypeValueData = this.noflyTypeValue;
        });
    },
    mounted() {
        this.noflySwitchData = this.noflySwitch;
        this.noflyCheckListData = this.noflyCheckList;
        this.noflyTypeValueData = this.noflyTypeValue;
    },
    methods: {
        ...mapMutations([
            'SET_NO_FLY_SWITCH',
            'SET_NO_FLY_CHECKLIST',
            'SET_NO_FLY_TYPE_VALUE'
        ]),
        // 初始化禁飞区纯展示图层（与禁飞区管理图层区分开）
        initNoflyShowLayer() {
            this.$options.noFlyZoneHook = userNoFlyZoneHook();
            this.$options.noFlyZoneHook.setMap({map3d: this.$options.map3d, layerID: 'noFlyLayer'});
        },
        // 禁飞区相关
        getNoFlyData() {
            if (this.requestTimer) {
                clearTimeout(this.requestTimer);
                this.requestTimer = null;
            }
            this.requestTimer = setTimeout(() => {
                this.clearNoFlyShowLayer();
                getNoFlyZoneList({flyZoneType: 1}).then(res => {
                    if (res.data.code == 200) {
                        this.noFlyData = res.data.data || [];
                        this.createNoFlyLayer(this.noFlyData, 'noFly');
                    }
                });
                getNoFlyZoneList({flyZoneType: 2}).then(res => {
                    if (res.data.code == 200) {
                        this.temporaryData = res.data.data || [];
                        this.createNoFlyLayer(this.temporaryData, 'temporary');
                    }
                });
                getNoFlyZoneList({flyZoneType: 3}).then(res => {
                    if (res.data.code == 200) {
                        this.heightLimitData = res.data.data || [];
                        this.createNoFlyLayer(this.heightLimitData, 'heightLimit');
                    }
                });
                getNoFlyZoneList({flyZoneType: 4}).then(res => {
                    if (res.data.code == 200) {
                        this.bufferZoneData = res.data.data || [];
                        this.createNoFlyLayer(this.bufferZoneData, 'bufferZone');
                    }
                });
                getNoFlyZoneList({flyZoneType: 5}).then(res => {
                    if (res.data.code == 200) {
                        this.customZoneData = res.data.data || [];
                        this.createNoFlyLayer(this.customZoneData, 'customZone');
                    }
                });
            }, 500);
        },
        // 清除禁飞区
        clearNoFlyShowLayer() {
            // if (this.$options.noFlyZoneHook.getRenderLayerEntityNum()) return;
            this.$options.noFlyZoneHook && this.$options.noFlyZoneHook.clearRenderLayer();
        },
        // 绘制禁飞区
        createNoFlyLayer(data, type) {
            let contents = [];
            data && data.map(t => contents.push({...t.content, id: t.id, name: t.name}));
            let layerStyle = {
                noFlyType: type,
                show: false,
                data: contents
            };
            if (this.noflySwitchData && this.noflyCheckListData.find(t => t === type)) layerStyle.show = true;
            switch (type) {
                case 'noFly':
                    layerStyle.overlayColor = '#FF1414';
                    layerStyle.overlayAlpha = 0.28;
                    layerStyle.outLineColor = '#FF1414';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'temporary':
                    layerStyle.overlayColor = '#FF1414';
                    layerStyle.overlayAlpha = 0.3;
                    layerStyle.polylineDash = true;
                    layerStyle.outLineColor = '#FFB600';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'heightLimit':
                    layerStyle.overlayColor = '#FFC300';
                    layerStyle.overlayAlpha = 0.3;
                    layerStyle.outLineColor = '#FFC300';
                    layerStyle.outLineWidth = 0;
                    break;
                case 'bufferZone':
                    layerStyle.overlayColor = '#B0B0B0';
                    layerStyle.overlayAlpha = 0.4;
                    layerStyle.outLineColor = '#2B2B2B';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'customZone':
                    layerStyle.overlayColor = '#C600FF';
                    layerStyle.overlayAlpha = 0.28;
                    layerStyle.outLineColor = '#C600FF';
                    layerStyle.outLineWidth = 2;
                    break;
            }
            this.$options.noFlyZoneHook.handlingNoFlyData(layerStyle);
        },
        // 关闭/打开所有类型禁飞区
        changenNoflySwitch(val) {
            this.SET_NO_FLY_SWITCH(val);
            this.noflyTypeValueData.map(t => t.disabled = !val);
            this.SET_NO_FLY_TYPE_VALUE(this.noflyTypeValueData);
            // 隐藏/打开所有禁飞区
            this.noflyCheckListData.map(t => {
                if (t && this[`${t}Data`]) {
                    this[`${t}Data`].map(i => {
                        this.$options.noFlyZoneHook.changeShowEntity(i.id, val);
                    });
                }
            });
        },
        // 关闭/打开单个类型禁飞区
        changeNoflyBox(val) {
            this.SET_NO_FLY_CHECKLIST(this.noflyCheckListData);
            if (!this[`${val}Data`]) return;
            let isShow = this.noflyCheckListData.find(t => t === val) ? true : false;
            this[`${val}Data`].map(t => {
                this.$options.noFlyZoneHook.changeShowEntity(t.id, isShow);
            });
        },

        // 不要判断是否显示隐藏禁飞区
        getNoFlyDataTwo() {
            if (!this.noflySwitch || !this.noflyCheckList.length) return;
            if (this.$options.noFlyZoneHook.getRenderLayerEntityNum()) return;
            this.$options.noFlyZoneHook.clearRenderLayer();
            this.noflyCheckList.map(t => {
                let typeList = {label: t};
                switch(t) {
                    case 'noFly':
                        typeList.flyZoneType = 1;
                        break;
                    case 'temporary':
                        typeList.flyZoneType = 2;
                        break;
                    case 'heightLimit':
                        typeList.flyZoneType = 3;
                        break;
                    case 'bufferZone':
                        typeList.flyZoneType = 4;
                        break;
                    case 'customZone':
                        typeList.flyZoneType = 5;
                        break;
                }
                getNoFlyZoneList({flyZoneType: typeList.flyZoneType}).then(res => {
                    if (res.data.code == 200) {
                        this.createNoFlyLayerTwo(res.data.data || [], typeList.label);
                    }
                });
            });
        },
        createNoFlyLayerTwo(data, type) {
            let contents = [];
            data && data.map(t => contents.push({...t.content, id: t.id, name: t.name}));
            let layerStyle = {
                noFlyType: type,
                show: true,
                data: contents
            };
            switch (type) {
                case 'noFly':
                    layerStyle.overlayColor = '#FF1414';
                    layerStyle.overlayAlpha = 0.28;
                    layerStyle.outLineColor = '#FF1414';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'temporary':
                    layerStyle.overlayColor = '#FF1414';
                    layerStyle.overlayAlpha = 0.3;
                    layerStyle.polylineDash = true;
                    layerStyle.outLineColor = '#FFB600';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'heightLimit':
                    layerStyle.overlayColor = '#FFC300';
                    layerStyle.overlayAlpha = 0.3;
                    layerStyle.outLineColor = '#FFC300';
                    layerStyle.outLineWidth = 0;
                    break;
                case 'bufferZone':
                    layerStyle.overlayColor = '#B0B0B0';
                    layerStyle.overlayAlpha = 0.4;
                    layerStyle.outLineColor = '#2B2B2B';
                    layerStyle.outLineWidth = 2;
                    break;
                case 'customZone':
                    layerStyle.overlayColor = '#C600FF';
                    layerStyle.overlayAlpha = 0.28;
                    layerStyle.outLineColor = '#C600FF';
                    layerStyle.outLineWidth = 2;
                    break;
            }
            this.$options.noFlyZoneHook.handlingNoFlyData(layerStyle);
        }
    },
    beforeDestroy() {
        this.$options.noFlyZoneHook && this.$options.noFlyZoneHook.clearNoFlyZone();
        this.$options.noFlyZoneHook = null;
    }
}
</script>