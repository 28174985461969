<template>
  <div class="easyPlayer-live-box">
    <div
      class="videoBox"
      v-show="videoLive">
      <EasyPlayer
        ref="EasyPlayer"
        :videoUrl="liveURL"
        :loading="videoLoading || !liveURL"
        :aspect="'16:9'"
        :showEnterprise="false"
        :reconnection="true"
        :speed="false">
      </EasyPlayer>
      <!-- 关闭按钮 -->
      <div
        class="top-menu">
      </div>
      <div 
        class="video-menu">
        <span class="update-btn" @click.stop="reloadVideo">
          <i style="font-size: 18px" v-if="videoLoading" class="el-icon-loading"></i>
          <i style="font-size: 18px" v-else class="el-icon-refresh-right"></i>
        </span>
        <span v-if="screen" style="margin-left: 8px" @click="getScreenShot">
          <img v-if="!screening" src="/img/map/texture-white.png" />
          <i v-else class="el-icon-loading"></i>
          {{$t('screenShot')}}
        </span>
        <el-dropdown
          :placement="'top'"
          @command="changeVideoURL"
          hide-on-click>
          <span class="el-dropdown-link" style="margin-left: 8px" >
            {{$t('videoSource')}}<i class="el-icon-arrow-up"></i>
          </span>
          <el-dropdown-menu class="live-source-dropdown" slot="dropdown">
            <el-dropdown-item  :command="item" v-for="item in liveVideoVOList" :key="item.id">
              {{ item.liveDevice }}
              {{item.id === currentId? `   √`: ''}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div
      :class="$i18n.locale ==='zh'? 'videoBtn':'videoBtn-en'"
      @click="showLive" v-show="!videoLive"
    >
    </div>
    <!-- 截图弹窗 -->
    <el-dialog
      append-to-body
      :title="$t('screenShot')"
      :visible.sync="screenDialogVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      destroy-on-close
      :before-close="resetForm"
      >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px">
        <el-form-item 
          :label="$t('live.warningType')"
          prop="alarmType">
          <select-tree
            v-model="form.alarmType"
            :data="alarmTypeList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <el-form-item
          :label="$t('warning.level')"
          prop="level">
          <select-tree
            v-model="form.level"
            :data="levelList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
      </el-form>
      <span 
        slot="footer"
        class="dialog-footer">
        <el-button
          @click="handlerCancel">
          {{$t('cancelText')}}
        </el-button>
        <el-button
          type="primary"
          @click="handlerOkScreen">
          {{$t('submitText')}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { 
  getArarmType 
} from "@/api/phugiacloud/projectManagement";
import { addScreen } from "@/api/alarm/alarm-data.js";
export default {
  props: {
    dialogData: {
      type: Object,
      default: {},
    },
    lonArr: {
      type: Array,
      default: [],
    },
    showclose: {
      type: Boolean,
      default(){
        return true
      }
    },
    // 是否需要报警按钮
    screen: {
      type: Boolean,
      default(){
        return true
      }
    },
    videoUrl: {
      type: String,
      default() {
        return ''
      }
    },
    liveVideoVOList: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
    currentId() {
      for (var i = 0; i < this.liveVideoVOList.length; i++) {
        let item = this.liveVideoVOList[i]
        if (item.webrtcPullStreamUrl === this.liveURL) {
          return item.id
        }
      }
      return ''
    }
  },

  watch: {
    liveVideoVOList: {
      handler() {
        this.changeLiveVideoVOList()
      },
      deep: true,
      immediate: true
    }
  },

  mounted(){
    this.$refs.EasyPlayer.showEnterprise = false
  },

  data() {
    return {
      player: null,
      // 截图表单弹窗
      screenDialogVisible: false,
      // 编辑表单
      form: { 
        alarmType: "",
        level: 3
      },
      // 验证
      rules: {
        alarmType: [
          { required: true, message: this.$t('live.chooseWarningType'), trigger: "change" },
        ],
        level: [
          { required: true, message: this.$t('selectPlease'), trigger: "change" },
        ]
      },
      // 报警类型列表
      alarmTypeList: [],
      // 视频状态
      videoLive: true,
      // 视频下载
      videoLoading: false,
      // 上传图片列表
      alarmPicBase64s: [],
      // 截图中
      screening: false,
      // 当前正在直播的url
      liveURL: '',
      // 报警级别(1-一级;2-二级;3-三级;4-四级)
      levelList: [
        {
          label: this.$t('warning.levelOne'),
          value: 1
        },
        {
          label: this.$t('warning.levelTwo'),
          value: 2
        },
        {
          label: this.$t('warning.levelThree'),
          value: 3
        },
        {
          label: this.$t('warning.levelFour'),
          value: 4
        }
      ]
    };
  },

  methods: {
    // 触发截图screenShot事件
    getScreenShot() {
      if (this.screening) {
        return
      }
      this.screening = true
      const todo = Promise.all([this.captureScreenshot(), this.getArarmType()]);
      todo.then(()=>{
        this.screening = false
        this.screenshotDialog();
      })
    },

    // 快捷截屏
    keydownScreenShot(){
      return new Promise((resolve, reject)=>{
        try {
          const todo = Promise.all([this.captureScreenshot(), this.getArarmType()]);
          todo.then(()=>{
            this.screening = false
            this.form.alarmType = 'other'
            setTimeout(()=>{
              this.handlerOkScreen(true)
            },500)
          }).finally(()=>{
            resolve()
          })
        }catch(e){
          reject(e)
        }
      })
    },

    screenshotDialog() {
      // 默认报警类型
      this.form.alarmType = 'other'
      this.screenDialogVisible = true;
    },

    getArarmType(){
      return new Promise((resolve, reject)=>{
        getArarmType({ taskId: this.dialogData.id })
        .then((res) => {
          this.alarmTypeList = res.data.data.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          });
          resolve(res)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    priceBlur(e) {
      let val = e.toString();
      if (val) {
        var index = val.toString().indexOf(".");
        if (index > -1) {
          var arr = val.split(".");
          var num = arr[1];
          if (num.length > 6) {
            return Number(arr[0] + "." + num.substr(0, 6));
          } else {
            return Number(val);
          }
        } else {
          return Number(val);
        }
      }
    },

    handlerCancel() {
      this.form.alarmType = "";
      this.screenDialogVisible = false;
    },

    captureScreenshot(shortcut) {
      // 由于快捷键截屏比快需要准备好了三张图 再resolve
      return new Promise((resolve, reject)=>{
        try {
          let count = 0; // 计数器，用于控制循环
          const interval = 500; // 循环间隔时间（单位：毫秒）
          this.alarmPicBase64s=[];
          const timer = setInterval(() => {
            this.doScreen();
            count++; // 每次循环计数器加1
            if (count === 2 && !shortcut) {
              resolve()
            }
            if (count >= 3) {
              if (shortcut) {
                resolve()
              }
              this.screening = false
              clearInterval(timer); // 清除定时器
            }
          }, interval);
        } catch (error) {
          reject(error)
        }
      })
    },

    doScreen(){
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      const ratio = window.devicePixelRatio || 1;
      ctx.scale(ratio, ratio);
      // canvas大小与图片大小保持一致，截图没有多余
      var video = document.querySelector('video');
      if (!video) return
      canvas.width = video.videoWidth * ratio;
      canvas.height = video.videoHeight * ratio;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.img = canvas.toDataURL();
      this.alarmPicBase64s.push(this.img);
    },

    handlerOkScreen(keydown) {
      const that = this;
      const selectedOption = this.alarmTypeList.find(item => item.value === this.form.alarmType);
      const data = {
        taskId: this.dialogData.id,
        projectId: this.dialogData.projectId,
        projectName: this.dialogData.projectName,
        taskName: this.dialogData.taskName,
        alarmType: this.form.alarmType,
        level: this.form.level,
        alarmTypeName: selectedOption.dictValue,
        lon: this.lonArr[0],
        lat: this.lonArr[1],
        alarmPicBase64s: this.alarmPicBase64s
      };
      if (data.lon && data.lat) {
        if (keydown === true) {
          // 如果通过快捷键截屏不要去做表单效验
          this.getGeocoder(data)
          return
        }
        that.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
        });
        this.handlerCancel();
        this.getGeocoder(data)
      } else {
        this.$message.warning(this.$t('LatitudeLongitudeNotObtained'));
      }
    },

    getGeocoder(data){
      // 使用经纬度获取地点
      const lngLat = [this.priceBlur(data.lon), this.priceBlur(data.lat)]
      AMap.plugin("AMap.Geocoder",  () => {
        var geocoder = new AMap.Geocoder();
        geocoder.getAddress(lngLat,  (status, result) => {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息
            data.address = result.regeocode.formattedAddress;
          }
          this.addScreen(data)
        })
      })
    },

    addScreen(data) {
      addScreen(data).then(
        () => {
          this.$message({
            type: "success",
            message: `${this.$t('operationSuccessfully')}!`,
          });
        },
        (error) => {
          // loading();
          console.log(error);
        }
      );
      this.alarmPicBase64s = []
    },

    resetForm() {
      this.form.alarmType = "";
      this.screenDialogVisible = false;
    },

    reloadVideo() {
      this.videoLoading = true
      this.$refs.EasyPlayer.initPlayer()
      setTimeout(()=>{
        this.videoLoading = false
      }, 500)
    },

    changeLiveVideoVOList () {
      this.videoLoading = true
      this.liveURL = this.liveVideoVOList.length > 0? this.liveVideoVOList[0].webrtcPullStreamUrl: ''
      setTimeout(()=>{
        this.videoLoading = false
      }, 500)
    },

    changeVideoURL(v) {
      this.videoLoading = true
      this.liveURL = v.webrtcPullStreamUrl
      setTimeout(()=>{
        this.videoLoading = false
      }, 500)
    },

    closeLive() {
      this.videoLive = false;
    },

    showLive() {
      this.videoLive = true;
      this.$emit('showLive')
    }
  },

  beforeDestroy() {
    this.$refs.EasyPlayer.destroyPlayer()
  }
};
</script>
<style lang="scss" >
.easyPlayer-live-box {
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  .videoBox {
    width: 100%;
    height: 100%;
    &:hover .video-menu{
      display: block;
      cursor: pointer;
    }
    .video-menu {
      display: none;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 100001;
      padding: 4px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6);
      img {
        width: 16px;
        height: 16px;
      }
      .update-btn{
        .span-text{
          background-color: #409EFF;
          border-radius: 2px;
          color: white;
        }
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #FFFFFF;
      }
    }
    .top-menu {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100001;
      padding: 4px;
      text-align: end;
      cursor: pointer;
      i {
        width: 16px;
        height: 16px;
      }
    }
    .video-tools{
      display: none !important;
    }
  }
  .videoBtn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 74px;
    height: 58px;
    background: #000000;
    border-radius: 3px;
    background: url('/img/taskCenter/taskPage/video_control.png') no-repeat;
  }
  .videoBtn-en {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 74px;
    height: 58px;
    background: #000000;
    border-radius: 3px;
    background: url('/img/taskCenter/taskPage/video_control_en.png') no-repeat;
  }

}
.live-source-dropdown {
  background: #222222DD;
  border: 0px;
  .el-dropdown-menu__item {
    color: #FFFFFF;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #555555;
    color: #FFFFFF;
  }
}
</style>
