<template>
  <div class="HWLLS-video-box">
    <div class="videoBox" v-show="videoLive">
      <div
        class="HWLLS-video"
        :id="'HWLLS-video' + currentTime"
        @dblclick.stop="fullScreen">
      </div>
      <div class="top-menu"></div>
      <div class="video-menu">
        <span class="update-btn" @click.stop="reloadVideo">
          <i style="font-size: 18px" v-if="videoLoading" class="el-icon-loading"></i>
          <i style="font-size: 18px" v-else class="el-icon-refresh-right"></i>
        </span>
        <span v-if="screen" style="margin-left: 8px" @click="getScreenShot">
          <img src="/img/map/texture-white.png"/>
          {{$t('screenShot')}}
        </span>
        <el-dropdown
          :placement="'top'"
          @command="changeVideoURL"
          hide-on-click>
          <span class="el-dropdown-link" style="margin-left: 8px" >
            {{$t('videoSource')}}<i class="el-icon-arrow-up"></i>
          </span>
          <el-dropdown-menu class="live-source-dropdown" slot="dropdown">
            <el-dropdown-item  :command="item" v-for="item in liveVideoVOList" :key="item.id">
              {{ item.liveDevice }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="videoBtn"  @click="showLive" v-show="!videoLive">
    </div>
    <!-- 截图弹窗 -->
    <el-dialog
      append-to-body
      :title="$t('screenShot')"
      :visible.sync="screenDialogVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      destroy-on-close
      :before-close="resetForm"
      >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item :label="$t('live.warningType')" prop="alarmType">
          <select-tree
            v-model="form.alarmType"
            :data="alarmTypeList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
        <el-form-item :label="$t('warning.level')" prop="level">
          <select-tree
            v-model="form.level"
            :data="levelList"
            size="small"
            :clearable="true"
            style="width: 248px"
            :placeholder="$t('selectPlease')"
          >
          </select-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlerCancel">{{$t('cancelText')}}</el-button>
        <el-button type="primary" @click="handlerOkScreen">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getArarmType } from "@/api/phugiacloud/projectManagement";
import { addScreen } from "@/api/alarm/alarm-data.js";
export default {
  props: {
    dialogData: {
      type: Object,
      default() {
        return null
      },
    },
    lonArr: {
      type: Array,
      default: [],
    },
    showclose: {
      type: Boolean,
      default(){
        return true
      }
    },
    screen: {
      type: Boolean,
      default(){
        return true
      }
    },
    videoUrl: {
      type: String,
      default() {
        return ''
      }
    },
    liveVideoVOList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    liveVideoVOList: {
      handler() {
        this.changeLiveVideoVOList()
      },
      deep: true
    }
  },
  data() {
    return {
      currentTime: new Date().getTime(),
      player: null,
      screenDialogVisible: false,
      // 表单
      form: { 
        alarmType: "",
        level: 3
      },
      // 效验
      rules: {
        alarmType: [
          { required: true, message: this.$t('live.chooseWarningType'), trigger: "change" },
        ],
        level: [
          { required: true, message: this.$t('selectPlease'), trigger: "change" },
        ]
      },
      // 报警类型列表
      alarmTypeList: [],
      // 视频状态
      videoLive: true,
      // 重新加载视频
      videoLoading: false,
      // 设置截屏的数量
      screenNumber: 3,
      // 是否全屏
      isFullScreen: false,
      // 当前正在直播的url
      liveURL: '',
       // 报警级别(1-一级;2-二级;3-三级;4-四级)
      levelList: [
        {
          label: this.$t('warning.levelOne'),
          value: 1
        },
        {
          label: this.$t('warning.levelTwo'),
          value: 2
        },
        {
          label: this.$t('warning.levelThree'),
          value: 3
        },
        {
          label: this.$t('warning.levelFour'),
          value: 4
        }
      ],
    };
  },
  created() {
    if (performance && performance.now) {
      this.currentTime = `${performance.now()}`.replace('.', '-');
    } else {
      this.currentTime = new Date().getTime();
    }
  },
  mounted() {
    this.initVideo()
  },
  methods: {
    initVideo(){
      let _this = this;
      this.$options.client = HWLLSPlayer.createClient("webrtc")
      // 音视频开启钩子
      this.$options.client.on('video-start', () => {
        const msg = document.getElementById(`HWLLS-video${_this.currentTime} HWLLS-video-message-box${_this.currentTime}`)
        if (!msg) {
          let player_container = document.querySelector(`#HWLLS-video${_this.currentTime} #player_container`)
          this.$options.videoMsg = document.createElement('DIV')
          this.$options.videoMsg.setAttribute('class', 'HWLLS-video-message-box')
          this.$options.videoMsg.setAttribute('id', `HWLLS-video${_this.currentTime} HWLLS-video-message-box${_this.currentTime}`)
          this.$options.videoMsg.innerHTML = `${this.$t('operationSuccessfully')}`
          player_container.appendChild(this.$options.videoMsg)
        }
      })
      // 初始化视频地址
      this.liveURL = this.liveVideoVOList.length > 0? this.liveVideoVOList[0].webrtcPullStreamUrl: ''
      // 初始化视频
      this.$options.client.startPlay(this.liveURL, {
        elementId: `HWLLS-video${_this.currentTime}`
      })
      // 全屏事件 
      this.$options.client.on('fullscreen-status-changed', (event) => {
        this.isFullScreen = event.isFullScreen
      })
      // 有截屏功能增加快捷键截屏提示
      if (this.screen) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: 
          `<div>
            ${this.$t('mission.press')}
            <span class="HWLLS-video-boder">Alt</span> + 
            <span class="HWLLS-video-boder">C</span> 
            ${this.$t('mission.forScreenshot')}
          </div>`,
          duration: 5000
        });
      }
    },
    
    keydownScreenShot(){
      return new Promise((resolve, reject)=>{
        try {
          const todo = Promise.all([this.captureScreenshot(), this.getArarmType()]);
          todo.then(()=>{
            this.screening = false
            this.form.alarmType = 'other'
            setTimeout(()=>{
              this.handlerOkScreen(true)
            },500)
          }).finally(()=>{
            resolve()
          })
        }catch(e){
          reject(e)
        }
      })
    },

    // 触发截图screenShot事件
    getScreenShot() {
      if (this.screening) {
        return
      }
      this.screening = true
      const todo = Promise.all([this.captureScreenshot(), this.getArarmType()]);
      todo.then(()=>{
        this.screening = false
        this.screenshotDialog();
      })
    },

    screenshotDialog() {
      // 默认报警类型
      this.form.alarmType = 'other'
      this.screenDialogVisible = true;
    },

    getArarmType(){
      return new Promise((resolve, reject)=>{
        getArarmType({ taskId: this.dialogData.id })
        .then((res) => {
          this.alarmTypeList = res.data.data.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          });
          resolve(res)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    priceBlur(e) {
      let val = e.toString();
      if (val) {
        var index = val.toString().indexOf(".");
        if (index > -1) {
          var arr = val.split(".");
          var num = arr[1];
          if (num.length > 6) {
            return Number(arr[0] + "." + num.substr(0, 6));
          } else {
            return Number(val);
          }
        } else {
          return Number(val);
        }
      }
    },

    handlerCancel() {
      this.form.alarmType = "";
      this.screenDialogVisible = false;
    },

    captureScreenshot(shortcut) {
      // 由于快捷键截屏比快需要准备好了三张图 再resolve
      return new Promise((resolve, reject)=>{
        try {
          let count = 0; // 计数器，用于控制循环
          const interval = 500; // 循环间隔时间（单位：毫秒）
          this.alarmPicBase64s=[];
          const timer = setInterval(() => {
            this.doScreen();
            count++; // 每次循环计数器加1
            if (count === this.screenNumber - 1 && !shortcut) {
              resolve()
            }
            if (count >= this.screenNumber) {
              if (shortcut) {
                resolve()
              }
              this.screening = false
              clearInterval(timer); // 清除定时器
            }
          }, interval);
        } catch (error) {
          reject(error)
        }
      })
    },

    doScreen(){
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      const ratio = window.devicePixelRatio || 1;
      ctx.scale(ratio, ratio);
      // canvas大小与图片大小保持一致，截图没有多余
      var video = document.querySelector(`#HWLLS-video${this.currentTime} video`);
      if (!video) return
      canvas.width = video.videoWidth * ratio;
      canvas.height = video.videoHeight * ratio;  
      // 绘制截图
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.img = canvas.toDataURL();
      this.alarmPicBase64s.push(this.img);
    },

    handlerOkScreen(keydown) {
      const selectedOption = this.alarmTypeList.find(item => item.value === this.form.alarmType);
      const data = {
        taskId: this.dialogData.id,
        projectId: this.dialogData.projectId,
        projectName: this.dialogData.projectName,
        taskName: this.dialogData.taskName,
        alarmType: this.form.alarmType,
        level: this.form.level,
        alarmTypeName: selectedOption.dictValue,
        lon: this.lonArr[0],
        lat: this.lonArr[1],
        alarmPicBase64s: this.alarmPicBase64s
      };

      if (data.lon && data.lat) {
        if (keydown === true) {
          // 如果通过快捷键截屏不要去做表单效验
          this.getGeocoder(data, ()=>{
            if (this.isFullScreen) {
              this.$options.videoMsg.style.display = 'block'
              setTimeout(() => {
                this.$options.videoMsg.style.display = 'none'
              }, 5000);
            }else {
              this.$message({
                type: "success",
                message: `${this.$t('operationSuccessfully')}!`,
              });
            }
            
          })
          return
        }
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          this.handlerCancel();
          this.getGeocoder(data, ()=>{
            this.$message({
              type: "success",
              message: `${this.$t('operationSuccessfully')}!`,
            });
          })
        });
      } else {
        this.$message.warning(this.$t('LatitudeLongitudeNotObtained'));
      }

    },

    getGeocoder(data, success){
      // 使用经纬度获取地点
      const lngLat = [this.priceBlur(data.lon), this.priceBlur(data.lat)]
      AMap.plugin("AMap.Geocoder",  () => {
        var geocoder = new AMap.Geocoder();
        geocoder.getAddress(lngLat,  (status, result) => {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息
            data.address = result.regeocode.formattedAddress;
          } 
          this.addScreen(data).then(res=>{
            if (success) {
              success()
            }
          })
        })
      })
    },

    addScreen(data) {
      return new Promise((resolve, reject)=>{
        addScreen(data).then(res => {
          resolve(res)
        }).catch(err=>{
          reject(err)
        }).finally(()=>{
          this.alarmPicBase64s = []
        })
      })
      
      
    },

    resetForm() {
      this.form.alarmType = "";
      this.screenDialogVisible = false;
    },

    fullScreen() {
      this.$options.client.fullScreenToggle(this.isFullScreen);
    },

    reloadVideo() {
      this.videoLoading = true
      this.$options.client.switchPlay(this.liveURL)
      setTimeout(()=>{
        this.videoLoading = false
      }, 500)
    },

    changeLiveVideoVOList () {
      if (!this.$options.client) {
        this.initVideo()
      } else {
        this.liveURL = this.liveVideoVOList.length > 0? this.liveVideoVOList[0].webrtcPullStreamUrl: ''
        this.reloadVideo()
      }
    },

    changeVideoURL(v) {
      this.liveURL = v.webrtcPullStreamUrl
      this.reloadVideo()
    },
    
    closeLive() {
      this.videoLive = false;
    },

    showLive() {
      this.videoLive = true;
      this.$emit('showLive')
    }
  },
  beforeDestroy() {
    // 关流
    this.$options.client.destoryClient()
  }
};
</script>
<style lang="scss">
.HWLLS-video-box {
  color: white;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  .videoBox {
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover .video-menu{
      display: block;
    }
    .HWLLS-video {
      height: 100%;
    }
    .video-menu {
      display: none;
      // width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100001;
      padding: 4px;
      background-color: rgba(0, 0, 0, 0.6);
      img {
        width: 16px;
        height: 16px;
      }
      .update-btn{
        .span-text{
          background-color: #409EFF;
          border-radius: 2px;
          color: white;
        }
        // color: #409EFF;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #FFFFFF;
      }
    }
    .top-menu {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100001;
      padding: 4px;
      text-align: end;
      cursor: pointer;
      i {
        width: 16px;
        height: 16px;
      }
    }
  }
  .videoBtn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 74px;
    height: 58px;
    background: #000000;
    border-radius: 3px;
    background: url('/img/taskCenter/taskPage/video_control.png') no-repeat;
  }
}
.HWLLS-video-boder {
  padding: 5px;
  border: 1px #aaa solid;
  border-radius: 4px;
}
.HWLLS-video-message-box{
  position: absolute;
  display: none;
  width: 200px;
  top: 50px;
  left: 50%;
  background-color: #000000DD;
  color: #ddd;
  transform: translateX(-50%);
  font-size: 20px;
  text-align: center;
  padding: 15px;
}
.live-source-dropdown {
  background: #222222DD;
  border: 0px;
  .el-dropdown-menu__item {
    color: #FFFFFF;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #555555;
    color: #FFFFFF;
  }
}
</style>
