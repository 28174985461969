<script>
import DrawPointOperation from "../js/interactive/operation/DrawPointOperation";
import DrawPolyLineOperation from "../js/interactive/operation/DrawPolyLineOperation";
import { labelEntity2geojson } from "@/util/map";
import oilInspectionPanel from "../components/oilInspection-panel";
import NodeMap from "../js/node/index";
import {
  // 油井
  saveOilwellpoint,
  getOilwellpoint,
  deleteOilwellpoint,
  // 储罐
  saveStorageTank,
  getStorageTank,
  deleteStorageTank,
  // 计量站
  saveMeasurementStation,
  getMeasurementStation,
  deleteMeasurementStation,
  // 油气站
  saveStationStorage,
  getStationStorage,
  deleteStationStorage,
  // 电力设施
  savePowerEquipment,
  getPowerEquipment,
  deletePowerEquipment,
  // 油井管线
  saveOilline,
  getOilline,
  deleteOilline,
  // 油田公路
  saveOilRoad,
  getOilRoad,
  deleteOilRoad,
  // 电力线路
  savePowerLine,
  getPowerLine,
  deletePowerLine,
  // 石油气井
  saveOilGasWell,
  getOilGasWell,
  deleteOilGasWell,
} from "@/api/project/map";

export default {
  components: { oilInspectionPanel },
  data() {
    return {
      showInspectionPanel: false,
      currentEntity: {},
      hoverTip: {
        type: "oilWell",
        id: "",
        show: false,
        left: 0,
        top: 0,
        list: [],
      },
    };
  },
  mounted() {
    this.$options.InspectionsQueue = []
    this.$options.InspectionsQueueTimer = null
  },
  methods: {
    // 绘制油井
    drawOilWell() {
      this.setDrawPointOperation(NodeMap.OilWellPoint);
    },

    // 绘制储罐
    drawStorageTank() {
      this.setDrawPointOperation(NodeMap.StorageTank);
    },

    // 绘制计量站
    drawMeasurementStation() {
      this.setDrawPointOperation(NodeMap.MeasurementStation);
    },

    // 绘制油气站库
    drawStationStorage() {
      this.setDrawPointOperation(NodeMap.StationStorage);
    },

    // 绘制电力设施
    drawPowerEquipment() {
      this.setDrawPointOperation(NodeMap.PowerEquipment);
    },

    // 绘制石油气井
    drawOilGasWell() {
      this.setDrawPointOperation(NodeMap.GasWell);
    },

    // 设置Point交互机制
    setDrawPointOperation(Node) {
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel();
        return;
      }
      this.activeDraw = Node.DRAWTYPE;
      this.drawFinish = false;
      this.tips.tip = "点击绘制";
      this.$options.map3d._container.style.cursor = "crosshair";
      const editLayer = this.inspectionLayers[this.currentLabelLayer];
      this.$options.map3d.setOperation(
        new DrawPointOperation({
          map: this.$options.map3d,
          drawType: Node.DRAWTYPE,
          layer: editLayer.layer,
          afterFinish: (entity, type, done) => {
            this.$options.map3d._container.style.cursor = "default";
            const cartographic = Cesium.Cartographic.fromCartesian(
              entity.position._value
            );
            const lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            const lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            const height = 0; // 高度
            // 发送后台保存
            this.getEntityInfoToSave(entity, NodeMap.Node.POINT)
              .then((data) => {
                done(data, Node);
                this.setInspectionEntity({
                  id: data.id,
                  type: NodeMap.Node.POINT,
                  drawType: Node.DRAWTYPE,
                  text: data.name,
                  lng,
                  lat,
                  height,
                  path: data.dirId,
                  linkman: data.linkman,
                  createBy: data.createBy,
                  deptId: data.deptId,
                  tel: data.tel,
                  meteringStation: data.meteringStation,
                  startCondition: data.startCondition,
                  endCondition: data.endCondition,
                });
                this.emitTreeUpdate();
                this.selectInspectionById(data.id);
                editLayer.pointCount++;
                this.openInspectionPanel();
              })
              .finally(() => {});
            this.drawFinish = true
            this.setEditModel()
          },
        })
      );
    },

    // 绘制石油管线
    drawOilPipeline() {
      this.setDrawLineOperation(NodeMap.OilPipeline);
    },

    // 绘制油田公路
    drawOilRoad() {
      this.setDrawLineOperation(NodeMap.OilRoad);
    },

    // 绘制电力线路
    drawPowerLine() {
      this.setDrawLineOperation(NodeMap.PowerLine);
    },

    // 设置画line交互机制
    setDrawLineOperation(Node) {
      if (!this.drawFinish) {
        this.drawFinish = true;
        this.setEditModel();
        return;
      }
      this.activeDraw = Node.DRAWTYPE;
      this.drawFinish = false;
      this.tips.tip = "点击绘制,双击结束";
      this.$options.map3d._container.style.cursor = "crosshair";
      const editLayer = this.inspectionLayers[this.currentLabelLayer].layer;
      this.$options.map3d.setOperation(
        new DrawPolyLineOperation({
          map: this.$options.map3d,
          layer: editLayer,
          drawType: Node.DRAWTYPE,
          afterFinish: (positions) => {
            this.getEntityInfoToSave(
              // 临时创建一个虚拟node使用
              new Node({
                id: Cesium.createGuid(),
                positions: positions,
              }).entity, 
              NodeMap.Node.LINE
            ).then((data) => {
              // 重新创建实体
              var node = new Node({
                id: data.id,
                positions: positions,
                createBy: data.createBy,
                name: data.name,
              });
              let entity = node.entity
              editLayer.addNode(node);
              this.inspectionLayers[this.currentLabelLayer].lineCount += 1;
              let points = node.getInflectionPoint();
              let linePositions = points.map((item) => {
                let position = item.entity.position._value;
                let cartographic =
                  Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
                return {
                  lon: Cesium.Math.toDegrees(cartographic.longitude),
                  lat: Cesium.Math.toDegrees(cartographic.latitude),
                  id: item.id,
                };
              });
              let distance = "0m";
              if (entity && entity.horiDistance) {
                let dis = entity.horiDistance.toFixed(2);
                let suffix = "m";
                if (dis > 1000) {
                  dis = (dis / 1000).toFixed(2);
                  suffix = "km";
                }
                distance = dis + suffix;
              }
              this.setInspectionEntity({
                id: data.id,
                type: NodeMap.Node.LINE,
                drawType: Node.DRAWTYPE,
                text: data.name,
                path: data.dirId,
                createBy: data.createBy,
                linePositions,
                linkman: data.linkman,
                deptId: data.deptId,
                tel: data.tel,
                meteringStation: data.meteringStation,
                startCondition: data.startCondition,
                endCondition: data.endCondition,
              });
              this.emitTreeUpdate();
              this.selectInspectionById(data.id);
              this.openInspectionPanel();
            });
            this.drawFinish = true;
            this.setEditModel()
            return;
          },
        })
      );
    },

    // 石油管线增加拐点
    addEntityOnLine(v) {
      let editLayer = null;
      let insertNode = null;
      for (let i = 0; i < this.inspectionLayers.length; i++) {
        var item = this.inspectionLayers[i];
        insertNode = item.layer.getNodeById(v.id);
        if (insertNode) {
          editLayer = item.layer;
          break;
        }
      }
      if (!editLayer) {
        throw "请查看该图元图层是否存在";
      }
      this.activeDraw = insertNode.drawType;
      this.drawFinish = false;
      this.tips.tip = "点击绘制";
      this.$options.map3d._container.style.cursor = "crosshair";
      this.$options.map3d.setOperation(
        new DrawPolyLineOperation({
          map: this.$options.map3d,
          layer: editLayer,
          drawType: insertNode.drawType,
          insert: true,
          insertIndex: v.index,
          insertNode,
          insertId: v.item.id,
          afterFinish: (node) => {
            let entity = node.entity
            this.$options.map3d._container.style.cursor = "default";
            let distance = "0m";
            if (entity && entity.horiDistance) {
              let dis = entity.horiDistance.toFixed(2);
              let suffix = "m";
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = "km";
              }
              distance = dis + suffix;
            }
            let points = node.getInflectionPoint();
            let linePositions = points.map((item) => {
              let position = item.entity.position._value;
              let cartographic =
                Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
              return {
                lon: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                id: item.id,
              };
            });
            this.updateInspection({
              id: entity.id,
              text: entity.label.text._value,
              positions: linePositions,
            }).then((res) => {
              if (res.data.code !== 200) {
                return;
              }
              switch (insertNode.drawType) {
                case NodeMap.OilPipeline.DRAWTYPE:
                  getOilline({
                    id: entity.id,
                  }).then((res) => {
                    if (res.data.code === 200) {
                      const data = res.data.data;
                      this.setInspectionEntity({
                        id: entity.id,
                        type: NodeMap.Node.LINE,
                        drawType: NodeMap.OilPipeline.DRAWTYPE,
                        text: data.name,
                        distance,
                        linePositions,
                        path: data.dirId,
                        createBy: data.createBy,
                        deptId: data.deptId,
                        linkman: data.linkman,
                        tel: data.tel,
                        meteringStation: data.meteringStation,
                        startCondition: data.startCondition,
                        endCondition: data.endCondition,
                      });
                    }
                  });
                  break;
                case NodeMap.OilRoad.DRAWTYPE:
                  getOilRoad({
                    id: entity.id,
                  }).then((res) => {
                    if (res.data.code === 200) {
                      const data = res.data.data;
                      this.setInspectionEntity({
                        id: entity.id,
                        type: NodeMap.Node.LINE,
                        drawType: NodeMap.OilRoad.DRAWTYPE,
                        text: data.name,
                        distance,
                        linePositions,
                        path: data.dirId,
                        createBy: data.createBy,
                        deptId: data.deptId,
                        linkman: data.linkman,
                        tel: data.tel,
                        meteringStation: data.meteringStation,
                        startCondition: data.startCondition,
                        endCondition: data.endCondition,
                      });
                    }
                  });
                  break;
                case NodeMap.PowerLine.DRAWTYPE:
                  getPowerLine({
                    id: entity.id,
                  }).then((res) => {
                    if (res.data.code === 200) {
                      const data = res.data.data;
                      this.setInspectionEntity({
                        id: entity.id,
                        type: NodeMap.Node.LINE,
                        drawType: NodeMap.PowerLine.DRAWTYPE,
                        text: data.name,
                        distance,
                        linePositions,
                        path: data.dirId,
                        createBy: data.createBy,
                        deptId: data.deptId,
                        linkman: data.linkman,
                        tel: data.tel,
                        meteringStation: data.meteringStation,
                        startCondition: data.startCondition,
                        endCondition: data.endCondition,
                      });
                    }
                  });
                  break;
                default:
                  break;
              }
            });
            this.setEditModel()
            return;
          },
        })
      );
      this.$options.map3d.interactive._current.selectInsertNode()
    },

    // 点选中石油巡检图元
    selectedOilNode(selectFeature) {
      let node;
      if (selectFeature instanceof NodeMap.Node) {
        node = selectFeature;
      }
      if (selectFeature && selectFeature.root instanceof NodeMap.Node) {
        node = selectFeature.root;
      }
      if (!node) {
        return;
      }
      let drawType = selectFeature && selectFeature.drawType;
      let cartographic, lng, lat, height;
      if (node.type === NodeMap.Node.POINT) {
        switch (drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getOilwellpoint({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.OilWellPoint.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getStorageTank({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.StorageTank.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.GasWell.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getOilGasWell({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.GasWell.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getMeasurementStation({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.MeasurementStation.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getStationStorage({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.StationStorage.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            // 更新右侧属性面板
            cartographic = Cesium.Cartographic.fromCartesian(
              node.position
            );
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度
            // 发送请求
            getPowerEquipment({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: selectFeature.id,
                    type: NodeMap.Node.POINT,
                    drawType: NodeMap.PowerEquipment.DRAWTYPE,
                    text: data.name,
                    lng,
                    lat,
                    path: data.dirId,
                    height: height.toFixed(1),
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          default:
            break;
        }
      } else if (node.type === NodeMap.Node.LINE) {
        let distance = "0m",
          horiDistance = 0,
          inflectionPoint,
          linePositions;
        inflectionPoint = node.getInflectionPoint();
        linePositions = inflectionPoint.map((item) => {
          let position = item.entity.position._value;
          let cartographic =
            Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
          return {
            lon: Cesium.Math.toDegrees(cartographic.longitude),
            lat: Cesium.Math.toDegrees(cartographic.latitude),
            id: item.id,
          };
        });
        // 找到线得总长度数据
        if (selectFeature.horiDistance) {
          horiDistance = selectFeature.horiDistance;
        } else if (selectFeature.root instanceof NodeMap.Line) {
          const entity = selectFeature.root.entity;
          horiDistance = entity.horiDistance;
        }
        // 转化单位
        let dis = horiDistance.toFixed(2);
        let suffix = "m";
        if (dis > 1000) {
          dis = (dis / 1000).toFixed(2);
          suffix = "km";
        }
        distance = dis + suffix;
        switch (drawType) {
          case NodeMap.OilPipeline.DRAWTYPE:
            // 获取后台业务数据
            getOilline({
              id: node.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: node.id,
                    type: NodeMap.Node.LINE,
                    drawType: NodeMap.OilPipeline.DRAWTYPE,
                    text: data.name,
                    distance,
                    linePositions,
                    path: data.dirId,
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            // 获取后台业务数据
            getOilRoad({
              id: node.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: node.id,
                    type: NodeMap.Node.LINE,
                    drawType: NodeMap.OilRoad.DRAWTYPE,
                    text: data.name,
                    distance,
                    linePositions,
                    path: data.dirId,
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            // 获取后台业务数据
            getPowerLine({
              id: node.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  const data = res.data.data;
                  this.setInspectionEntity({
                    id: node.id,
                    type: NodeMap.Node.LINE,
                    drawType: NodeMap.PowerLine.DRAWTYPE,
                    text: data.name,
                    distance,
                    linePositions,
                    path: data.dirId,
                    createBy: data.createBy,
                    deptId: data.deptId,
                    linkman: data.linkman,
                    tel: data.tel,
                    meteringStation: data.meteringStation,
                    startCondition: data.startCondition,
                    endCondition: data.endCondition,
                  });
                }
              })
              .finally(() => {
                this.openInspectionPanel();
              });
            break;
          default:
            break;
        }
      }
    },

    // 关闭右侧属性面板
    closeInspectionPanel() {
      let operation = this.$options.map3d.interactive._current
      if (operation && operation.selectFeature && operation.selectFeature.category === NodeMap.Node.INSPECTION) {
        this.$options.map3d.interactive._current.cancelSelectFeature();
      }
      this.showInspectionPanel = false;
    },

    // 开启右侧属性面板
    openInspectionPanel() {
      this.showInspectionPanel = true;
    },

    // 设置右侧面板属性
    setInspectionEntity(value) {
      this.currentEntity = Object.assign({}, value);
    },

    // 更新地图中的实体
    updateInspection(entityInfo, done) {
      return new Promise((resolve, reject) => {
        let geojson, params, node, entity, position, positions;
        const id = entityInfo.id;
        for (let i = 0; i < this.inspectionLayers.length; i++) {
          const layer = this.inspectionLayers[i].layer;
          entity = layer.getById(id);
          if(entity){
            node = entity.root;
          }
          if (node) break;
        }
        if (!node) {
          throw "未在地图中找到应对entity, 无法更新";
        }
        const drawType = entity.drawType;
        const projectId = this.$route.query.projectId;
        const dirId = entityInfo.path;
        const deptId = entityInfo.deptId;
        const linkman = entityInfo.linkman;
        const tel = entityInfo.tel;
        const meteringStation = entityInfo.meteringStation;
        const deptName = entityInfo.deptName;
        switch (drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveOilwellpoint(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveStorageTank(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.GasWell.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveOilGasWell(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveMeasurementStation(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveStationStorage(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            // 更改前端对应图层
            position = Cesium.Cartesian3.fromDegrees(
              entityInfo.lng,
              entityInfo.lat,
              Number(entityInfo.height)
            );
            entity.label.text._value = entityInfo.text;
            entity.position = position;
            // 向后台发送请求
            geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
            params = {
              id: entityInfo.id,
              content: geojson,
              projectId,
              dirId,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            savePowerEquipment(params).then((res) => {
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.OilPipeline.DRAWTYPE:
            node.name = entityInfo.text;
            positions = entityInfo.positions.map((item) => {
              return Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0);
            });
            node.positions = positions;
            geojson = labelEntity2geojson(
              this.$options.map3d,
              node.entity,
              "LineString"
            );
            params = {
              content: geojson,
              projectId,
              dirId,
              id,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveOilline(params).then((res) => {
              if (done) {
                done();
              }
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            node.name = entityInfo.text;
            positions = entityInfo.positions.map((item) => {
              return Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0);
            });
            node.positions = positions;
            geojson = labelEntity2geojson(
              this.$options.map3d,
              node.entity,
              "LineString"
            );
            params = {
              content: geojson,
              projectId,
              dirId,
              id,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            saveOilRoad(params).then((res) => {
              if (done) {
                done();
              }
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            node.name = entityInfo.text;
            positions = entityInfo.positions.map((item) => {
              return Cesium.Cartesian3.fromDegrees(item.lon, item.lat, 0);
            });
            node.positions = positions;
            geojson = labelEntity2geojson(
              this.$options.map3d,
              node.entity,
              "LineString"
            );
            params = {
              content: geojson,
              projectId,
              dirId,
              id,
              name: entityInfo.text,
              deptId,
              linkman,
              tel,
              meteringStation,
              deptName,
            };
            savePowerLine(params).then((res) => {
              if (done) {
                done();
              }
              resolve(res);
              this.emitTreeUpdate();
            });
            break;
        }
      });
    },

    // 更新地图中的实体
    updateInspectionByOperation(node) {
      return new Promise((resolve, reject) => {
        let geojson, params, entity, position, positions, drawType, type, cartographic, lng, lat, height ,distance;
        entity = node.entity
        drawType = node.drawType
        type = node.type
        switch (type) {
          case NodeMap.Node.LINE:
            if (entity && entity.horiDistance) {
              let dis = entity.horiDistance.toFixed(2);
              let suffix = "m";
              if (dis > 1000) {
                dis = (dis / 1000).toFixed(2);
                suffix = "km";
              }
              distance = dis + suffix;
            }
            const points = node.getInflectionPoint()
            const linePositions = points.map((item) => {
              let position = item.entity.position._value;
              let cartographic =
                Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
              return {
                lon: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                id: item.id,
              };
            });
            switch (drawType) {
              case NodeMap.OilPipeline.DRAWTYPE:
                geojson = labelEntity2geojson(
                  this.$options.map3d,
                  node.entity,
                  "LineString"
                );
                params = {
                  content: geojson,
                  id: node.id,
                };
                saveOilline(params).then((res) => {
                  if (res.data.code == 200) {
                    var data = res.data.data
                    this.setInspectionEntity({
                      id: node.id,
                      type: NodeMap.Node.LINE,
                      drawType,
                      text: data.name,
                      distance,
                      linePositions,
                      path: data.dirId,
                      createBy: data.createBy,
                      deptId: data.deptId,
                      linkman: data.linkman,
                      tel: data.tel,
                      meteringStation: data.meteringStation,
                      startCondition: data.startCondition,
                      endCondition: data.endCondition,
                    });
                  }
                  resolve(res);
                });
                break;
              case NodeMap.OilRoad.DRAWTYPE:
                geojson = labelEntity2geojson(
                  this.$options.map3d,
                  node.entity,
                  "LineString"
                );
                params = {
                  content: geojson,
                  id: node.id,
                };
                saveOilRoad(params).then((res) => {
                  if (res.data.code == 200) {
                    var data = res.data.data
                    this.setInspectionEntity({
                      id: node.id,
                      type: NodeMap.Node.LINE,
                      drawType,
                      text: data.name,
                      distance,
                      linePositions,
                      path: data.dirId,
                      createBy: data.createBy,
                      deptId: data.deptId,
                      linkman: data.linkman,
                      tel: data.tel,
                      meteringStation: data.meteringStation,
                      startCondition: data.startCondition,
                      endCondition: data.endCondition,
                    });
                  }
                  resolve(res);
                });
                break;
              case NodeMap.PowerLine.DRAWTYPE:
                geojson = labelEntity2geojson(
                  this.$options.map3d,
                  node.entity,
                  "LineString"
                );
                params = {
                  content: geojson,
                  id: node.id,
                };
                savePowerLine(params).then((res) => {
                  if (res.data.code == 200) {
                    var data = res.data.data
                    this.setInspectionEntity({
                      id: node.id,
                      type: NodeMap.Node.LINE,
                      drawType,
                      text: data.name,
                      distance,
                      linePositions,
                      path: data.dirId,
                      createBy: data.createBy,
                      deptId: data.deptId,
                      linkman: data.linkman,
                      tel: data.tel,
                      meteringStation: data.meteringStation,
                      startCondition: data.startCondition,
                      endCondition: data.endCondition,
                    });
                  }
                  resolve(res);
                });
                break;
            }
            break;
          case NodeMap.Node.POINT:
            cartographic = Cesium.Cartographic.fromCartesian(node.position);
            lng = Number(
              Cesium.Math.toDegrees(cartographic.longitude).toFixed(7)
            ); // 经度
            lat = Number(
              Cesium.Math.toDegrees(cartographic.latitude).toFixed(7)
            ); // 纬度
            height = cartographic.height < 0 ? 0 : cartographic.height; // 高度\
            switch (drawType) {
              case NodeMap.OilWellPoint.DRAWTYPE:
                // 向后台发送请求
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                saveOilwellpoint(params).then((res) => {
                  if (res.data.code === 200) {
                    getOilwellpoint({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
              case NodeMap.StorageTank.DRAWTYPE:
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                saveStorageTank(params).then((res) => {
                  if (res.data.code === 200) {
                    getStorageTank({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
              case NodeMap.GasWell.DRAWTYPE:
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                saveOilGasWell(params).then((res) => {
                  if (res.data.code === 200) {
                    getOilGasWell({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType: NodeMap.OilWellPoint.DRAWTYPE,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
              case NodeMap.MeasurementStation.DRAWTYPE:
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                saveMeasurementStation(params).then((res) => {
                  if (res.data.code === 200) {
                    getMeasurementStation({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
              case NodeMap.StationStorage.DRAWTYPE:
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                saveStationStorage(params).then((res) => {
                  if (res.data.code === 200) {
                    getStationStorage({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
              case NodeMap.PowerEquipment.DRAWTYPE:
                geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
                params = {
                  id: node.id,
                  content: geojson,
                };
                savePowerEquipment(params).then((res) => {
                  if (res.data.code === 200) {
                    getPowerEquipment({
                      id: node.id
                    }).then(res=>{
                      if (res.data.code == 200) {
                        let data = res.data.data
                        this.setInspectionEntity({
                          id: data.id,
                          type: NodeMap.Node.POINT,
                          drawType,
                          text: data.name,
                          lng,
                          lat,
                          path: data.dirId,
                          height: height.toFixed(1),
                          createBy: data.createBy,
                          deptId: data.deptId,
                          linkman: data.linkman,
                          tel: data.tel,
                          meteringStation: data.meteringStation,
                          startCondition: data.startCondition,
                          endCondition: data.endCondition
                        })
                      }
                    })
                  }
                });
                break;
            }
            break;
          default:
            break;
        }
      });
    },

    // 
    removeEntityOnLine(v) {
      let editLayer;
      // 获取编辑的图层
      for (let i = 0; i < this.inspectionLayers.length; i++) {
        const layer = this.inspectionLayers[i].layer;
        entity = layer.getById(v.id);
        if (entity) {
          editLayer = entity.editLayer;
          break;
        }
      }
      if (!editLayer) {
        return;
      }
      var node = editLayer.getNodeById(v.id);
      var entity = node._entity;
      node.deletePointNode(v);
      let points = node.getInflectionPoint();
      let linePositions = points.map((item) => {
        let position = item.entity.position._value;
        let cartographic =
          Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
        return {
          lon: Cesium.Math.toDegrees(cartographic.longitude),
          lat: Cesium.Math.toDegrees(cartographic.latitude),
          id: item.id,
        };
      });
      this.$options.map3d._container.style.cursor = "default";
      let distance = "0m";
      if (entity && entity.horiDistance) {
        let dis = entity.horiDistance.toFixed(2);
        let suffix = "m";
        if (dis > 1000) {
          dis = (dis / 1000).toFixed(2);
          suffix = "km";
        }
        distance = dis + suffix;
      }
      this.updateInspection({
        id: v.id,
        type: node.drawType,
        positions: linePositions,
        text: entity.label.text._value,
      }).then((res) => {
        if (res.data.code !== 200) {
          return;
        }
        switch (node.drawType) {
          case NodeMap.OilPipeline.DRAWTYPE:
            getOilline({
              id: entity.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.setInspectionEntity({
                  id: v.id,
                  type: NodeMap.Node.LINE,
                  drawType: NodeMap.OilPipeline.DRAWTYPE,
                  text: data.name,
                  distance,
                  linePositions,
                  path: data.dirId,
                  createBy: data.createBy,
                  deptId: data.deptId,
                  linkman: data.linkman,
                  tel: data.tel,
                  meteringStation: data.meteringStation,
                  startCondition: data.startCondition,
                  endCondition: data.endCondition,
                });
              }
            });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            getOilRoad({
              id: entity.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.setInspectionEntity({
                  id: v.id,
                  type: NodeMap.Node.LINE,
                  drawType: NodeMap.OilRoad.DRAWTYPE,
                  text: data.name,
                  distance,
                  linePositions,
                  path: data.dirId,
                  createBy: data.createBy,
                  deptId: data.deptId,
                  linkman: data.linkman,
                  tel: data.tel,
                  meteringStation: data.meteringStation,
                  startCondition: data.startCondition,
                  endCondition: data.endCondition,
                });
              }
            });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            getPowerLine({
              id: entity.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.setInspectionEntity({
                  id: v.id,
                  type: NodeMap.Node.LINE,
                  drawType: NodeMap.PowerLine.DRAWTYPE,
                  text: data.name,
                  distance,
                  linePositions,
                  path: data.dirId,
                  createBy: data.createBy,
                  deptId: data.deptId,
                  linkman: data.linkman,
                  tel: data.tel,
                  meteringStation: data.meteringStation,
                  startCondition: data.startCondition,
                  endCondition: data.endCondition,
                });
              }
            });
            break;
          default:
            break;
        }
      });
    },

    // 删除
    deleteInspection(id, done) {
      let entity;
      for (let i = 0; i < this.inspectionLayers.length; i++) {
        const layer = this.inspectionLayers[i].layer;
        entity = layer.getById(id);
        if (entity) {
          // 向后台发送请求
          switch (entity.drawType) {
            case NodeMap.OilPipeline.DRAWTYPE:
              deleteOilline({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.OilRoad.DRAWTYPE:
              deleteOilRoad({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.PowerLine.DRAWTYPE:
              deletePowerLine({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.OilWellPoint.DRAWTYPE:
              deleteOilwellpoint({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.StorageTank.DRAWTYPE:
              deleteStorageTank({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.GasWell.DRAWTYPE:
              deleteOilGasWell({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.MeasurementStation.DRAWTYPE:
              deleteMeasurementStation({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.StationStorage.DRAWTYPE:
              deleteStationStorage({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            case NodeMap.PowerEquipment.DRAWTYPE:
              deletePowerEquipment({
                ids: id,
              })
                .then(() => {
                  layer.removeNodeById(id);
                  this.closeInspectionPanel();
                  this.emitTreeUpdate();
                  this.$message.success("操作成功");
                })
                .finally(() => {});
              break;
            default:
              break;
          }
          break;
        }
      }
    },

    // 删除多个
    deleteEntities(ids) {
      let layer, entity;
      for (let i = 0; i < this.inspectionLayers.length; i++) {
        const _layer = this.inspectionLayers[i].layer;
        entity = _layer.getById(ids[0]);
        if (entity) {
          layer = _layer;
          // 向后台发送请求
          break;
        }
      }
      switch (entity.drawType) {
        case NodeMap.OilPipeline.DRAWTYPE:
          deleteOilline({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.OilRoad.DRAWTYPE:
          deleteOilRoad({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.PowerLine.DRAWTYPE:
          deletePowerLine({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.OilWellPoint.DRAWTYPE:
          deleteOilwellpoint({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.StorageTank.DRAWTYPE:
          deleteStorageTank({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.GasWell.DRAWTYPE:
          deleteOilGasWell({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.MeasurementStation.DRAWTYPE:
          deleteMeasurementStation({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.StationStorage.DRAWTYPE:
          deleteStationStorage({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        case NodeMap.PowerEquipment.DRAWTYPE:
          deletePowerEquipment({
            ids: id,
          })
            .then(() => {
              for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                entity = layer.getById(id);
                if (entity) {
                  layer.removeNodeById(id);
                }
              }
              this.closeInspectionPanel();
              this.emitTreeUpdate();
              this.$message.success("操作成功");
            })
            .finally(() => {});
          break;
        default:
          break;
      }
    },

    //
    visibilityInspection(ids, flag, requestBack) {
      let arr = ids.split(",");
      let layer;
      // 找到图层
      for (let i = 0; i < this.inspectionLayers.length; i++) {
        const _layer = this.inspectionLayers[i].layer;
        let node = _layer.getNodeById(arr[0]);
        if (node) {
          layer = _layer;
          break;
        }
      }
      if (!layer) {
        return;
      }
      for (let i = 0; i < arr.length; i++) {
        let id = arr[i];
        let node = layer.getNodeById(id);
        if (node) {
          node.show = flag;
          if (arr.indexOf(this.currentEntity.id) > -1) {
            this.showInspectionPanel = false;
          }
          if (!requestBack) {
            continue
          }
          let drawType = node.drawType;
          switch (drawType) {
            case NodeMap.OilWellPoint.DRAWTYPE:
              saveOilwellpoint({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.StorageTank.DRAWTYPE:
              saveStorageTank({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.GasWell.DRAWTYPE:
              saveOilGasWell({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.MeasurementStation.DRAWTYPE:
              saveMeasurementStation({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.StationStorage.DRAWTYPE:
              saveStationStorage({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.PowerEquipment.DRAWTYPE:
              savePowerEquipment({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.OilPipeline.DRAWTYPE:
              saveOilline({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.OilRoad.DRAWTYPE:
              saveOilRoad({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
            case NodeMap.PowerLine.DRAWTYPE:
              savePowerLine({
                id,
                visibility: flag ? 1 : 0,
              }).then((res) => {
                if (i === arr.length) {
                  this.emitTreeUpdate();
                }
              });
              break;
          }
        }
      }
    },

    // 选择巡检对象
    selectInspectionById(id) {
      if (this.$options.map3d.interactive._current.selectFeatureByEntity) {
        let entity;
        for (let i = 0; i < this.inspectionLayers.length; i++) {
          const layer = this.inspectionLayers[i].layer;
          entity = layer.getById(id);
          if (entity) {
            this.$options.map3d.interactive._current.selectFeatureByEntity(
              entity
            );
            break;
          }
        }
      }
    },

    // 巡检对象更新左树
    emitTreeUpdate() {
      this.$EventBus.$emit("left-tree-update");
    },

    // 清理图层，更新图元
    reloadInspection() {
      this.clearinspectionLayers();
      this.projectId = this.$route.query.projectId;
      this.requestInspectLabel(this.projectId);
    },

    //
    openHovertip(node) {
      this.hoverTip.left = node.left;
      this.hoverTip.top = node.top;
      this.hoverTip.type = node.type;
      this.hoverTip.drawType = node.drawType;
      this.hoverTip.id = node.id;
      const fn = () => {
        if (!this.hoverTip.id) return;
        switch (this.hoverTip.drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            getOilwellpoint({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            getStorageTank({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.GasWell.DRAWTYPE:
            getOilGasWell({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            getMeasurementStation({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            getStationStorage({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            getPowerEquipment({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                const data = res.data.data;
                this.hoverTip.show = true;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.OilPipeline.DRAWTYPE:
            getOilline({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                this.hoverTip.show = true;
                const data = res.data.data;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            getOilRoad({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                this.hoverTip.show = true;
                const data = res.data.data;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            getPowerLine({
              id: this.hoverTip.id,
            }).then((res) => {
              if (res.data.code === 200) {
                this.hoverTip.show = true;
                const data = res.data.data;
                let list = [];
                for (var key in data) {
                  switch (key) {
                    case "linkman":
                      list.push({
                        label: this.$t("contactPerson"),
                        value: data[key],
                      });
                      break;
                    case "tel":
                      list.push({
                        label: this.$t("contactTelephone"),
                        value: data[key],
                      });
                      break;
                    case "name":
                      list.push({
                        label: this.$t("name"),
                        value: data[key],
                      });
                      break;
                    case "meteringStation":
                      list.push({
                        label: `${this.$t(
                          "inspection.meteringstation"
                        )}/${this.$t("inspection.waterRoom")}`,
                        value: data[key],
                      });
                      break;
                    case "deptName":
                      list.push({
                        label: `${this.$t("inspection.possessionUnit")}`,
                        value: data[key],
                      });
                      break;
                    default:
                      break;
                  }
                }
                this.hoverTip.list = list;
              }
            });
            break;
          default:
            this.openScreenMakerHovertip && this.openScreenMakerHovertip(node);
            break;
        }
      };
      if (!this.$options.HovertipTimer) {
        fn();
        this.$options.HovertipTimer = setTimeout(() => {
          fn();
          clearTimeout(this.$options.HovertipTimer);
          this.$options.HovertipTimer = null;
        }, 200);
      }
    },

    //
    closeHovertip() {
      this.hoverTip.show = false;
      this.hoverTip.drawType = NodeMap.Node.POINT;
      this.hoverTip.id = "";
      this.hoverTip.left = 0;
      this.hoverTip.top = 0;
      this.hoverTip.list = [];
      clearTimeout(this.$options.HovertipTimer);
      this.$options.HovertipTimer = null;
    },

    // 
    clearinspectionLayers() {
      this.endInspectionQueue()
      this.inspectionLayers.forEach((x) => {
        this.$options.map3d.layerManager.remove(x.layer);
      });
      this.inspectionLayers = [];
      this.currentLabelLayer = 0;
    },

    // mark是后台返回数据
    createInspectionsIntoLayer(marks, layer) {
      for (let i = 0; i < marks.length; i++) {
        let Constructor = null
        const mark = marks[i];
        const geojson = mark.content;
        const type = geojson.geometry.type;
        const drawType = mark.objectType;
        switch (drawType) {
          case NodeMap.OilWellPoint.DRAWTYPE:
            Constructor = NodeMap.OilWellPoint
            break;
          case NodeMap.StorageTank.DRAWTYPE:
            Constructor = NodeMap.StorageTank
            break;
          case NodeMap.GasWell.DRAWTYPE:
            Constructor = NodeMap.GasWell
            break;
          case NodeMap.MeasurementStation.DRAWTYPE:
            Constructor = NodeMap.MeasurementStation
            break;
          case NodeMap.StationStorage.DRAWTYPE:
            Constructor = NodeMap.StationStorage
            break;
          case NodeMap.PowerEquipment.DRAWTYPE:
            Constructor = NodeMap.PowerEquipment
            break;
          case NodeMap.OilPipeline.DRAWTYPE:
            Constructor = NodeMap.OilPipeline
            break;
          case NodeMap.OilRoad.DRAWTYPE:
            Constructor = NodeMap.OilRoad
            break;
          case NodeMap.PowerLine.DRAWTYPE:
            Constructor = NodeMap.PowerLine
            break;
          default:
            continue
        }
        this.addInspectionQueue({
          mark,
          Constructor,
          layer,
          type
        })
      }
      if (!this.$options.InspectionsQueueTimer) {
        this.startInspectionQueue(50)
      }
      return this.$options.InspectionsQueue.length 
    },

    delayedRenderPointNode(args) {
      let { mark, Constructor, layer } = args
      const geojson = mark.content;
      const coordinates = geojson.geometry.coordinates;
      const node = new Constructor({
        id: mark.id,
        name: mark.name,
        createBy: mark.linkname,
        show: !!mark.visibility,
        position: Cesium.Cartesian3.fromDegrees(...coordinates),
      })
      layer.addNode(node)
      return 1
    },

    delayedRenderPolylineNode(args) {
      let { mark, Constructor, layer } = args
      const geojson = mark.content;
      const coordinates = geojson.geometry.coordinates;
      let positions = [];
      for (let k = 0; k < coordinates.length; k++) {
        const coordinate = coordinates[k];
        const position = Cesium.Cartesian3.fromDegrees(...coordinate);
        positions.push(position);
      }
      const node = new Constructor({
        id: mark.id,
        positions,
        createBy: mark.createBy,
        show: !!mark.visibility,
        name: mark.name,
      });
      layer.addNode(node)
      return positions.length
    },

    addInspectionQueue(args) {
      this.$options.InspectionsQueue.push(args)
    },

    spliceInspectionQueue(n) {
      return this.$options.InspectionsQueue.splice(0, n)
    },

    startInspectionQueue(num) {
      if (this.$options.InspectionsQueue.length === 0) {
        this.endInspectionQueue()
        return
      }
      let marks = this.spliceInspectionQueue(num)
      for (var i = 0; i < marks.length; i++) {
        let item = marks[i]
        let layer = item.layer
        switch (item.type) {
          case "Point":
            this.delayedRenderPointNode(item)
            layer.pointCount = layer.pointCount + 1
            break;
          case "LineString":
            this.delayedRenderPolylineNode(item)
            layer.lineCount = layer.lineCount + 1
            break;
          default:
            break;
        }
      }
      this.$options.InspectionsQueueTimer = setTimeout(()=>{
        this.startInspectionQueue(num)
      }, 50)
    },

    endInspectionQueue() {
      if (this.$options.InspectionsQueueTimer) {
        clearTimeout(this.$options.InspectionsQueueTimer)
        this.$options.InspectionsQueueTimer = null
      }
      this.$options.InspectionsQueue = []
    },

    // entity转geojson保存
    getEntityInfoToSave(entity, type) {
      return new Promise((resolve, reject) => {
        const projectId = this.$route.query.projectId;
        const drawType = entity.drawType;
        let markDirId, geojson, params;
        if (this.handlerType && this.handlerType.params) {
          markDirId = this.handlerType.params.groupId;
        }
        // 保存石油管线的信息
        if (type === NodeMap.Node.LINE) {
          geojson = labelEntity2geojson(
            this.$options.map3d,
            entity,
            "LineString"
          );
          params = {
            content: geojson,
            projectId,
            dirId: markDirId,
          };
          switch (drawType) {
            case NodeMap.OilPipeline.DRAWTYPE:
              saveOilline(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.OilRoad.DRAWTYPE:
              geojson = labelEntity2geojson(
                this.$options.map3d,
                entity,
                "LineString"
              );
              params = {
                content: geojson,
                projectId,
                dirId: markDirId,
              };
              saveOilRoad(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.PowerLine.DRAWTYPE:
              geojson = labelEntity2geojson(
                this.$options.map3d,
                entity,
                "LineString"
              );
              params = {
                content: geojson,
                projectId,
                dirId: markDirId,
              };
              savePowerLine(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            default:
              break;
          }
        } else if (type === NodeMap.Node.POINT) {
          geojson = labelEntity2geojson(this.$options.map3d, entity, "Point");
          params = {
            content: geojson,
            projectId,
            dirId: markDirId,
          };
          switch (drawType) {
            case NodeMap.OilWellPoint.DRAWTYPE:
              saveOilwellpoint(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.StorageTank.DRAWTYPE:
              saveStorageTank(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.GasWell.DRAWTYPE:
              saveOilGasWell(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.MeasurementStation.DRAWTYPE:
              saveMeasurementStation(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.StationStorage.DRAWTYPE:
              saveStationStorage(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            case NodeMap.PowerEquipment.DRAWTYPE:
              savePowerEquipment(params)
                .then((res) => {
                  const code = res.data.code;
                  if (code === 200) {
                    // 更换点图元的id
                    const data = res.data.data;
                    resolve(data);
                    this.$message.success("操作成功");
                  }
                })
                .catch(() => {
                  reject();
                });
              break;
            default:
              break;
          }
        }
      });
    }
  }
};
</script>
