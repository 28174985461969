import BaseOperation from "./BaseOperation";
import NodeMap from "../../node/index"
import {
  screen2cartesian3
} from "@/util/map";


export default class DrawPolyLineOperation extends BaseOperation {
  static NAME = "DRAW_LINE"
  constructor(props) {
    let defaultParam = Object.assign({
      name: DrawPolyLineOperation.NAME,
      eventMap: [
        {
          type: "LEFT_DOWN",
          callback: (map, event, layer) => {},
        },
        {
          type: "LEFT_CLICK",
          callback: (map, event, layer) => {
            this.click(map, event, layer);
          },
        },
        {
          type: "MOUSE_MOVE",
          callback: (map, event, layer) => {
            this.mousemove(map, event, layer);
          },
        },
        {
          type: "LEFT_DOUBLE_CLICK",
          callback: (map, event, layer) => {
            this.doubleclick(map, event, layer);
          },
        },
      ]
    }, props);
    super(defaultParam);
    this._primitive = null
    this._mousePosition = null
    this._drawType = defaultParam.drawType;
    this._insert = props.insert? props.insert: false
    this._insertIndex = props.insertIndex
    this._insertNode = props.insertNode
    this._insertId = props.insertId
    this._drawing = false;
    this._points = [];
  }

  set primitive(value) {
    this._primitive = value
  }

  get primitive() {
    return this._primitive
  }

  click(map, event, layer) {
    let nowCartesian = screen2cartesian3(map, event.position);
    if (!nowCartesian) {
      return;
    }
    this._drawing = true
    if (this._insert) {
      map._container.style.cursor = "default"
      this.insertPoint(nowCartesian)
    } else {
      const pick = map.scene.pick(event.position);
      if (pick && pick.id) {
        const node = pick.id.root
        if (this.primitive && this.primitive.isOwerInflectionPoint(node)) {
          return
        }
      }
      this._addPointCall(nowCartesian)
    }
  }

  mousemove(map, event, layer) {
    let nowCartesian = screen2cartesian3(map, event.endPosition);
    if (nowCartesian) {
      this._mousePosition = nowCartesian
    }
    if (this.primitive) {
      this.primitive.draw([...this._points, this._mousePosition])
    }
  }

  doubleclick(map, event, layer) {
    // 插入点双击不起作用
    if (this._insert) return
    this._finish()
    map._container.style.cursor = "default"
    this._drawing = false;

  }

  _addPointCall(pointCartesian) {
    if (!this.primitive) {
      this.primitive = new NodeMap.TemporaryMarkLine({
        id: Cesium.createGuid(),
        name: 'TemporaryMarkLine',
        positions: [pointCartesian, this._mousePosition]
      })
      this.layer.addNode(this.primitive);
    } else {
      this.primitive.addInflectionPoint(pointCartesian)
    }
    this._points.push(pointCartesian)
  }

  _finish() {
    if (this._afterFinish) {
      this._afterFinish(this._points);
    }
  }

  insertPoint(nowCartesian) {
    this._insertNode.insertNode({
      index: this._insertIndex,
      perId: this._insertId,
      position: nowCartesian
    })
    if (this._afterFinish) {
      this._afterFinish(this._insertNode);
    }
  }

  selectInsertNode() {
    if (this._insertNode) {
      this._insertNode.onSelect()
    }
  }

  destroy() {
    if (this.primitive) {
      this.layer.removeNodeById(this.primitive.id);
    }
    this._drawing = false;
    this.primitive = null;
    this._points = [];
  }
}
