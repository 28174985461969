var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "HWLLS-video-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.videoLive,
              expression: "videoLive",
            },
          ],
          staticClass: "videoBox",
        },
        [
          _c("div", {
            staticClass: "HWLLS-video",
            attrs: { id: "HWLLS-video" + _vm.currentTime },
            on: {
              dblclick: function ($event) {
                $event.stopPropagation()
                return _vm.fullScreen($event)
              },
            },
          }),
          _c("div", { staticClass: "top-menu" }),
          _c(
            "div",
            { staticClass: "video-menu" },
            [
              _c(
                "span",
                {
                  staticClass: "update-btn",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.reloadVideo($event)
                    },
                  },
                },
                [
                  _vm.videoLoading
                    ? _c("i", {
                        staticClass: "el-icon-loading",
                        staticStyle: { "font-size": "18px" },
                      })
                    : _c("i", {
                        staticClass: "el-icon-refresh-right",
                        staticStyle: { "font-size": "18px" },
                      }),
                ]
              ),
              _vm.screen
                ? _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "8px" },
                      on: { click: _vm.getScreenShot },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/map/texture-white.png" },
                      }),
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("screenShot")) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-dropdown",
                {
                  attrs: { placement: "top", "hide-on-click": "" },
                  on: { command: _vm.changeVideoURL },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-dropdown-link",
                      staticStyle: { "margin-left": "8px" },
                    },
                    [
                      _vm._v("\n          " + _vm._s(_vm.$t("videoSource"))),
                      _c("i", { staticClass: "el-icon-arrow-up" }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "live-source-dropdown",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.liveVideoVOList, function (item) {
                      return _c(
                        "el-dropdown-item",
                        { key: item.id, attrs: { command: item } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.liveDevice) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.videoLive,
            expression: "!videoLive",
          },
        ],
        staticClass: "videoBtn",
        on: { click: _vm.showLive },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: _vm.$t("screenShot"),
            visible: _vm.screenDialogVisible,
            width: "30%",
            center: "",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "before-close": _vm.resetForm,
          },
          on: {
            "update:visible": function ($event) {
              _vm.screenDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("live.warningType"),
                    prop: "alarmType",
                  },
                },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.alarmTypeList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.form.alarmType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "alarmType", $$v)
                      },
                      expression: "form.alarmType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("warning.level"), prop: "level" } },
                [
                  _c("select-tree", {
                    staticStyle: { width: "248px" },
                    attrs: {
                      data: _vm.levelList,
                      size: "small",
                      clearable: true,
                      placeholder: _vm.$t("selectPlease"),
                    },
                    model: {
                      value: _vm.form.level,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "level", $$v)
                      },
                      expression: "form.level",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlerCancel } }, [
                _vm._v(_vm._s(_vm.$t("cancelText"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerOkScreen },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }