<script>
import { mapGetters } from "vuex";
export default {
    name: 'GreenLandProvider',
    data(){
        return {}
    },
    computed: {
        ...mapGetters([
            // 当前进入项目的详细信息
            "editProject",
        ]),
    },
    methods: {
        initGreenLand(map) {
            if (this.$options.greenLandProvider) {
                this.removeGreenLand()
            }
            let query = this.editProject.shpFileUrl
            if (!query) {
                return
            }
            map = map? map: this.$options.map3d
            query = JSON.parse(query)
            const greenLandProvider = new Cesium.WebMapServiceImageryProvider({
                url: query.url,
                parameters: {
                    format: 'image/png',
                    transparent: true
                },
                layers : query.layer
            })
            this.$options.greenLandProvider = map.imageryLayers.addImageryProvider(greenLandProvider)
        },

        removeGreenLand(map) {
            map = map? map: this.$options.map3d
            map.imageryLayers.remove(this.$options.greenLandProvider, true)
            this.$options.greenLandProvider = null
        },

        showGreenLand() {
            if (!this.$options.greenLandProvider) {
                this.initGreenLand()
            }
        },

        hiddenGreenLand () {
           this.removeGreenLand()
        }
    }
}
</script>